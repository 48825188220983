// Content
import { nanoid } from 'nanoid';

// Components
import Program from './sub-components/Program';

// Themes
import theme from '@/themes/index';

export const ProgramTypes = ({
  content: {
    title,
    subtitle,
    programs,
  },
}) => {
  return (
    <>
      <div className='container'>
        <div className='header-container'>
          <h3>{title}</h3>
          <p>{subtitle}</p>
        </div>
        <div className='programs-container'>
          {programs.map(({
            program, 
          }) => (
            <Program
              key={nanoid()}
              programName={program.name}
              callToAction={program.callToAction}
              backgroundImageUrl={
                program.image.url
              }
            />
          ))}
        </div>
      </div>
      <style jsx>{`
        .container {
          width: 100%;
          text-align: center;
          display: flex;
          flex-direction: column;
        }

        .header-container {
          flex-direction: row;
        }

        .programs-container {
          flex-direction: row;
          padding: 2.4rem 0 0;
        }

        p,
        h3 {
          margin-block-start: 0;
          margin-block-end: 0;
          margin-inline-start: 0;
          margin-inline-end: 0;
        }

        h3 {
          font-family: ${theme.fontFamilies.montserratMedium};
          padding-bottom: 0.5rem;
          line-height: 2rem;
        }

        p {
          color: ${theme.fontColors.primary};
          font-family: ${theme.fontFamilies.montserrat};
          line-height: 1.5rem;
          text-align: center;
        }

        @media only screen and (min-width: ${theme.breakpoints.tabletMin}) {
          h3 {
            line-height: 5.375rem;
          }
        }

        @media only screen and (min-width: ${theme.breakpoints.desktopMin}) {
          h3 {
            line-height: 5.375rem;
            padding-bottom: 0.813rem;
          }

          .programs-container {
            display: flex;
            width: 100%;
            gap: 1.5rem;
            padding: 4.25rem 0 0;
          }

          p {
            line-height: 2.25rem;
            font-size: 1.5rem;
          }
        }
      `}</style>
    </>
  );
};

export default ProgramTypes;
