// Node Modules
import ReactPlayer from 'react-player';

// Themes
import theme from '@/themes/index';

const OurVideos = ({
  isDesktop,
  content: {
    title,
    urls, 
  }, 
}) => {
  const videos = urls.map(({
    videoUrl: {
      url, 
    },
  }) => url);

  return (
    <>
      {urls?.length && (
        <div className='container'>
          <h3 className='title'>{title}</h3>
          <div className='video-container'>
            <ReactPlayer url={videos} height={!isDesktop ? 450 : '37.938rem'} width={isDesktop ? '73.875rem' : '100%'}/>
          </div>
        </div>
      )}
      <style jsx>{`
        div.container {
          width: 100%;
          text-align: center;
        }

        div.video-container {
          display: flex;
          justify-content: center;
        }

        h3.title {
          margin-top: 0;
          font-family: ${theme.fontFamilies.montserratMedium};
          line-height: 2.5rem;
          padding-bottom: 3rem;
          margin-block-start: 0;
          margin-block-end: 0;
          margin-inline-start: 0;
          margin-inline-end: 0;
        }
      `}</style>
    </>
  );
};

export default OurVideos;
