import { fetchAPI } from '@/services/prismic';
import { camelize } from '@/scripts/camelize';

const getTopProgramsQuery = `
query getTopPrograms {
  allStyle_cards(tags: ["Programs", "Top Program"]) {
    edges {
      node {
        _meta {
          tags
        }
        card_content {
          ... on Styled_card_content {
            title
            summary
            url {
              ... on _ExternalLink {
                url
                target
              }
            }
          }
        }
        image
      }
    }
  }
}
`;

export const fetchTopProgramsContent =  async previewData => {
  const {
    allStyle_cards: {
      edges: topPrograms = [],
    } = {},
  } = await fetchAPI(getTopProgramsQuery, {
    previewData,
  });

  return camelize({
    topPrograms,
  });
};

export const getTopProgramsContent = content => {
  const topPrograms =  JSON.parse(JSON.stringify(content));

  const programs = topPrograms.programs.reduce(
    (accumulator, currentValue) => {

      const program = {
        title: currentValue.node.cardContent.title,
        url: currentValue.node.cardContent.url,
        description: currentValue.node.cardContent.summary,
        image: currentValue.node.image,
        status: currentValue.node.meta.tags.find(tag => tag.toLowerCase().indexOf('new') !== -1),
      }

      const card = {
        card: {
          ...program,
        },
      }

      accumulator.push(card);

      return accumulator;
    },
    []
  );

  topPrograms.cards = [...programs];
  
  return topPrograms;
}