//components
import Button from '@/components/global-components/Button';

// Themes
import theme from '@/themes/index';

const Program = ({
  programName,
  callToAction,
  backgroundImageUrl,
}) => (
  <>
    <div className='container'>
      <div className='info-container'>
        <h4>{programName}</h4>
        <Button
          text={callToAction.title}
          href={callToAction.url}
          target={callToAction.openInAnotherPage ? '_blank' : '_self'}
          width='8.75rem'
          className='tertiary'
        ></Button>
      </div>
    </div>
    <style jsx>{`
      .container {
        height: 32rem;
        background-image: url(${backgroundImageUrl});
        background-position: center center;
        background-size: cover;
        text-align: center;
        flex-direction: row;
        margin-bottom: 1.6rem;
        position: relative;
      }

      .info-container {
        padding-left: 1rem;
        text-align: left;
        padding-top: 18.5rem;
        position: absolute;
        bottom: 1rem;
      }

      h4 {
        font-family: ${theme.fontFamilies.openSansBold};
        color: ${theme.fontColors.neutral};
        line-height: 1.8rem;
        margin-block-start: 0;
        margin-block-end: 1rem;
        margin-inline-start: 0;
        margin-inline-end: 0;
      }

      @media only screen and (min-width: ${theme.breakpoints.desktopMin}) {
        .container {
          display: flex;
          width: 100%;
          height: 30rem;
        }

        .info-container {
          padding-left: 1rem;
          padding-top: 27.5rem;
        }
      }
    `}</style>
  </>
);

export default Program;
