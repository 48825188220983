import { connect } from 'react-redux';

// Actions
import {
  setDesktop,
  setMobile,
  setTablet,
} from '@/store/actions/viewPortActions';

// Components
import HomeBody from '@/components/home/HomeBody';

// queries
import {
  fetchHomeContent, fetchAlliancesBannerContent 
} from '@/graphql/home';

import App from '../src/App';

const Home = ({
  viewPorts,
  setDesktop,
  setMobile,
  setTablet,
  content,
  sharedContent,
}) => {
  const metaData = {
    canonicalUrl: process.env.CPI_URL_HOSTNAME,
    headTitle: content.metaTitle || '',
    metaDescription: content.metaDescription || '',
    noIndex: false,
  };

  return (
    <App
      viewPorts={viewPorts}
      setDesktop={setDesktop}
      setMobile={setMobile}
      setTablet={setTablet}
      isMobile={viewPorts.isMobile}
      isDesktop={viewPorts.isDesktop}
      meta={metaData}
      content={sharedContent}>
      <HomeBody
        isMobile={viewPorts.isMobile}
        isDesktop={viewPorts.isDesktop}
        content={content}
      />
    </App>
  );
};

export const getStaticProps = async ({
  // eslint-disable-next-line no-unused-vars
  preview = false,
  previewData,
}) => {
  const content = await fetchHomeContent(previewData);
  const alliancesBannerContent = await fetchAlliancesBannerContent(previewData);

  return {
    props: {
      content: {
        ...content,
        alliances: {
          ...alliancesBannerContent,
          description: alliancesBannerContent.subTitle,
          institutions: [
            ...alliancesBannerContent.banner,
          ],
        },
      },
    },
    revalidate: parseInt(
      process.env.CPI_REVALIDATE_STATIC_REGEN_TIME || '7200',
      10
    ),
  };
};

const mapStateToProps = ({
  viewPorts,
}) => ({
  viewPorts,
});
const mapDispatchToProps = dispatch => ({
  setDesktop: () => dispatch(setDesktop()),
  setMobile: () => dispatch(setMobile()),
  setTablet: () => dispatch(setTablet()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
