// Node Modules
import { RichText } from 'prismic-reactjs';

import Bullet from '@/components/global-components/bullet/Bullet';

// Themes
import theme from '@/themes/index';
import { nanoid } from 'nanoid';

const Alliances = ({
  content,
}) => {
  const {
    title,
    description,
    institutions,
  } = content;
  return (
    <>
      <div className='container'>
        <h3>{title}</h3>
        <div className='alliances-description'>
          <RichText render={description} />
        </div>
        <div className='institution-container'>
          {institutions.map(({
            institutionOption: bullet, 
          }) => (
            <Bullet key={nanoid()} content={bullet} />
          ))}
        </div>
      </div>
      <style jsx>{`
        div.container {
          text-align: center;
          margin: 0 1.5em;
        }

        div.container p {
          line-height: 1.5rem;
        }

        div.container h3 {
          line-height: 2.5rem;
          margin-block-start: 0.5rem;
          margin-block-end: 0.5rem;
          margin-inline-start: 0;
          margin-inline-end: 0;
        }

        div.container .alliances-description {
          display: flex;
          justify-content: center;
        }

        div.container div.institution-container {
          align-items: center;
          display: flex;
          flex-direction: column;
        }

        @media only screen and (min-width: ${theme.breakpoints.desktopMin}) {
          div.container p {
            margin-block-end: 4rem;
          }

          div.container div.institution-container {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: flex-start;
          }
        }
      `}</style>
      <style global jsx>{`
        div.container .alliances-description p {
          margin-block-start: 0;
          margin-block-end: 5rem;
          line-height: 1.5rem;
          text-align: center;
        }

        @media only screen and (min-width: ${theme.breakpoints.desktopMin}) {
          div.container .alliances-description p {
            margin-block-end: 4rem;
            font-size: 1.5rem;
            text-align: center;
            line-height: 2.25rem;
            max-width: 59rem;
          }
        }
      `}</style>
    </>
  );
};

export default Alliances;
