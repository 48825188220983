// Node Modules
import { RichText } from 'prismic-reactjs';
import {useEffect} from 'react'
//components
import Button from '@/components/global-components/Button';

//themes
import theme from '@/themes/index';

export const TopBanner = ({
  backgroundImage: {
    url: backgroundImageUrl,
  },
  title,
  subTitle,
  callToActions = [],
  target,
  style,
  subTitleStyle,
}) => {
  const primaryAction = callToActions.find(a => a?.callToAction?.type?.toLowerCase() === 'primary');
  const secondaryAction = callToActions.find(a => a?.callToAction?.style?.toLowerCase() === 'secondary');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/assets/fslightbox.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);
  return (
    <>
      <div className='top-banner-container' style={style}>
        <h1 className='hero'>{title}</h1>
        <div className='sub-title' style={subTitleStyle}>
          <RichText render={subTitle} />
        </div>
        
        {(primaryAction || secondaryAction) && (
          <div className='cta-buttons-container'>
            {primaryAction && <a className='btn ghost watch-video' data-fslightbox href={primaryAction?.callToAction?.url}>
              {primaryAction?.callToAction?.title}
            </a>}
            
            {secondaryAction && <Button
              text={secondaryAction?.callToAction?.title}
              href={secondaryAction?.callToAction?.url}
              target={target}
              width='9.125rem'
              className='primary learn-more'
              tabIndex={1}
            ></Button>}
          </div>
        )}
      </div>

      <style jsx>
        {`
        .top-banner-container {
          width: 100%;
          background-image: url(${backgroundImageUrl});
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          padding: 15rem 1rem 15rem;
          text-align: center;
          filter: grayscale(10%);
          min-height: 25rem;
        }

        .top-banner-container p {
          text-align: center;
        }

        .cta-buttons-container {
          display: flex;
          justify-content: center;
        }

        div h1 {
          color: ${theme.fontColors.neutral};
          margin-bottom: 0;
          margin-top: 0;
          line-height: 3.5rem;
          font-size: 3.5rem;
        }

        div.sub-title {
          display: flex;
          flex-direction: column;
        }

        @media only screen and (min-width: ${theme.breakpoints.tabletMin}) {
          .top-banner-container {
            padding: 10rem 1rem 10rem;
          }

          .cta-buttons-container {
            gap: 1em;
          }
        }

        @media only screen and (min-width: ${theme.breakpoints.desktopMin}) {
          div.top-banner-container {
            padding: 10rem 5rem 15rem;
          }

          div h1 {
            color: ${theme.fontColors.neutral};
            width: 100%;
            max-width: 1482px;
            margin-block-start: 0;
            margin-block-end: 0;
            margin-inline-start: 0;
            margin-inline-end: 0;
            display: inline-block;
            line-height: 5.75rem;
            font-size: 4.6rem;
          }
        }
      `}
      </style>

      <style global jsx>{`
        .top-banner-container .btn.watch-video,
        .top-banner-container .btn.learn-more {
          font-weight: bold;
          height: 3.375rem;
        }

        .top-banner-container .btn.watch-video {
          border: 1px solid #ffff;
          color: ${theme.fontColors.neutral};
          margin-right: 1rem;
        }

        div.sub-title p {
          color: ${theme.fontColors.neutral};
          padding-top: 1rem;
          padding-bottom: 1rem;
          margin-block-start: 0;
          margin-block-end: 0;
          margin-inline-start: 0;
          margin-inline-end: 0;
          display: inline-block;
          line-height: 1.375rem;
          opacity: 1;
        }

        @media only screen and (min-width: ${theme.breakpoints.desktopMin}) {
          div.sub-title p {
            width: 100%;
            font-size: 1.5em;
            line-height: 1.7em;
            text-align: center;
          }
        }
      `}</style>
    </>
  )
};

export default TopBanner;
