import { fetchAPI } from '@/services/prismic';
import { camelize } from '@/scripts/camelize';

const getPostsQuery = `
  query getPosts($first: Int, $last: Int, $searchText: String!, $before: String!, $after: String!) {
    allPosts(lang: "en-us", sortBy: date_DESC, first: $first, last: $last, fulltext: $searchText, before: $before, after: $after) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          meta_title
          meta_description
          title
          location
          date
          time_reading
          summary
          text
          preview_image
          full_image
          action_button {
            ... on Call_to_action {
              title
              type
              style
            }
          }
          social {
            ... on Social_network {
              social_networks {
                social_network {
                  ... on Social_network_item {
                    title
                    icon
                    url
                  }
                }
              }
            }
          }
          _meta {
            id
          }
        }
      }
    }
  }
`;

const getPostsById = `
  query getPostsById($id: String!) {
    allPosts(id: $id) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          meta_title
          meta_description
          title
          location
          date
          time_reading
          summary
          text
          preview_image
          full_image
          action_button {
            ... on Call_to_action {
              title
              type
              style
            }
          }
          social {
            ... on Social_network {
              social_networks {
                social_network {
                  ... on Social_network_item {
                    title
                    icon
                    url
                  }
                }
              }
            }
          }
          _meta {
            id
          }
        }
      }
    }
  }
`;

const getPostIds = `
  query getPostIds {
    allPosts {
      edges {
        node {
          _meta {
            id
          }
        }
      }
    }
  }
`;

export const fetchPostsContent = async (first, last, searchText, before, after, previewData) => {
  const {
    allPosts: {
      totalCount,
      pageInfo,
      edges: posts = [],
    } = {},
  } = await fetchAPI(getPostsQuery, {
    previewData,
    variables: {
      first,
      last,
      searchText,
      before,
      after,
    },
  });

  return camelize({
    postsInfo: {
      totalCount, pageInfo,
    },
    posts,
  });
};

export const fetchPostByIdContent = async (id = '', previewData) => {
  const {
    allPosts: {
      pageInfo,
      edges: posts = [],
    } = {},
  } = await fetchAPI(getPostsById, {
    previewData,
    variables: {
      id,
    },
  });

  return camelize({
    postsInfo: {
      pageInfo,
    },
    posts,
  });
}

export const getCardsPostsContent = recentPosts => recentPosts?.reduce((accumulator, currentValue) => {
  const post = {
    title: currentValue.node.title,
    description: currentValue.node.summary,
    image: currentValue.node.previewImage,
    url: {
      target: '_self', url:`/post/${currentValue.node.meta.id}`,
    },
  }
  const card = {
    card: {
      ...post,
    },
  }

  accumulator.push(card);

  return accumulator;
}, []);

export const getRecentPostsContent = content => {
  const recentPosts = JSON.parse(
    JSON.stringify(content)
  );

  const posts = getCardsPostsContent(recentPosts);

  recentPosts.cards = [...posts];
  recentPosts.title = 'Recent Posts';

  return recentPosts;
}

export const fetchPostIds = async (previewData = false) => {
  const {
    allPosts: {
      edges: posts = [],
    } = {},
  } = await fetchAPI(getPostIds, {
    previewData,
  });

  return camelize(posts);
}