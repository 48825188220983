import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useEffect} from 'react'
//themes
import theme from '@/themes/index';

export const Button = ({
  text,
  width,
  type,
  styles,
  disabled,
  className,
  customAttr,
  href,
  target,
  tabIndex = 0,
  fontAwesomeIconName,
  fontAwesomeTitleText,
  onClick,
}) => {
  let buttonType;
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/assets/fslightbox.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  if (typeof type !== 'undefined') {
    switch (type) {
      case 'reset':
      case 'submit':
        buttonType = type;
        break;

      default:
        buttonType = 'button';
        break;
    }
  }
  
  return (
    <>
      {typeof buttonType === 'undefined' ? (
        <a
          {...customAttr}
          tabIndex={tabIndex}
          className={`btn ${
            typeof className !== 'undefined' ? className : 'primary'
          }`}
          href={typeof href !== 'undefined' ? href : undefined}
          style={styles}
          disabled={disabled}
          onClick={onClick}
          target={typeof target !== 'undefined' ? target : undefined}
          rel={target ? 'noopener noreferrer' : null}
        >
          {typeof fontAwesomeIconName !== 'undefined' ? (
            <div className='fa-icon'>
              <FontAwesomeIcon
                icon={fontAwesomeIconName}
                title={`${
                  typeof fontAwesomeTitleText !== 'undefined'
                    ? fontAwesomeTitleText
                    : ''
                }`}
              ></FontAwesomeIcon>
            </div>
          ) : (
            ''
          )}
          {`${typeof text !== 'undefined' ? text : 'Default'}`}
        </a>
      ) : (
        <button
          type={buttonType}
          disabled={disabled}
          onClick={onClick}
          style={styles}
          className={`btn ${
            typeof className !== 'undefined' ? className : 'primary'
          }`}
          tabIndex={tabIndex}
        >
          {typeof fontAwesomeIconName !== 'undefined' ? (
            <span className='fa-icon'>
              <FontAwesomeIcon
                icon={fontAwesomeIconName}
                title={`${
                  typeof fontAwesomeTitleText !== 'undefined'
                    ? fontAwesomeTitleText
                    : ''
                }`}
              ></FontAwesomeIcon>
            </span>
          ) : (
            ''
          )}
          {`${typeof text !== 'undefined' ? text : 'Default'}`}
        </button>
      )}

      <style jsx global>
        {`
          .btn {
            border: 0;
            border-radius: 1.6875em;
            cursor: pointer;
            align-items: center;
            text-decoration: none;
            font: normal normal normal 1em/1.25em OpenSansBold;
            opacity: 1;
            width: 100%;
            min-height: 2.375rem;
            background-color: transparent;
            max-width: ${typeof width !== 'undefined' ? width : 'fit-content'};
            display: flex;
            justify-content: center;
            padding: 1rem;
          }

           {
            /* Primary Buttons */
          }
          .btn.primary {
            background-color: ${theme.buttons.colors.primary};
            color: ${theme.buttons.fontColors.primary};
          }
          .btn.primary:hover {
            background-color: ${theme.buttons.colors.primaryHover};
            cursor: pointer;
          }
          .btn.primary:active {
            background-color: ${theme.buttons.colors.primaryPress};
          }
          .btn.primary[disabled],
          .btn.primary:disabled {
            background-color: ${theme.buttons.colors.primaryDisable};
          }
           {
            /* Secondary Buttons */
          }
          .btn.secondary {
            background-color: ${theme.buttons.colors.secondary};
            color: ${theme.buttons.fontColors.primary};
          }
          .btn.secondary:hover {
            background-color: ${theme.buttons.colors.secondaryHover};
          }
          .btn.secondary:active {
            background-color: ${theme.buttons.colors.secondaryPress};
            border: 1px solid #bbbbbb;
          }
          .btn.secondary[disabled],
          .btn.secondary:disabled {
            background-color: ${theme.buttons.colors.secondaryDisable};
          }
           {
            /* Ghost Buttons */
          }
          .btn.ghost {
            background-color: ${theme.buttons.colors.ghost};
            color: ${theme.buttons.fontColors.secondary};
            border: 1px solid #000000;
          }
          .btn.ghost:hover {
            background-color: ${theme.buttons.colors.ghostHover};
            color: ${theme.buttons.fontColors.primary};
            border-color: ${theme.buttons.colors.ghostHover};
          }
          .btn.ghost:active {
            background-color: ${theme.buttons.colors.ghostPress};
            color: ${theme.buttons.fontColors.primary};
            border-color: ${theme.buttons.colors.ghostPress};
          }
          .btn.ghost[disabled],
          .btn.ghost:disabled {
            background-color: ${theme.buttons.colors.ghostDisable};
            border: 1px solid #e5e5e5;
            color: #e5e5e5;
          }

           {
            /* Tertiary Buttons */
          }
          .btn.tertiary {
            font-family: ${theme.fontFamilies.openSansBold};
            background-color: ${theme.backgroundColors.primary};
            color: ${theme.buttons.fontColors.secondary};
            height: 3.375em;
            border: 0;
          }
          .btn.tertiary:hover {
            background-color: ${theme.buttons.colors.ghostHover};
            color: ${theme.buttons.fontColors.primary};
            border: 0;
          }
          .btn.tertiary:active {
            background-color: ${theme.buttons.colors.ghostPress};
            color: ${theme.buttons.fontColors.primary};
            border: 0;
          }
          .btn.tertiary[disabled],
          .btn.tertiary:disabled {
            background-color: ${theme.buttons.colors.ghostDisable};
            border: 1px solid #e5e5e5;
            color: #e5e5e5;
          }
        `}
      </style>
    </>
  );
};

export default Button;
