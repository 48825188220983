// Node Modules
import { RichText } from 'prismic-reactjs';

import { nanoid } from 'nanoid';

// Themes
import theme from '@/themes/index';

const Bullet = ({
  content,
  bulletStylesContainer, 
}) => {
  const {
    name,
    description,
    logos = [], 
  } = content;

  return (
    <>
      <div className='container' style={bulletStylesContainer}>
        <div className='logos'>
          {logos.map(({
            bulletLogo: {
              alt,
              url, 
            }, 
          }) => (
            <img key={nanoid()} src={url} alt={alt} />
          ))}
        </div>
        <div>
          {name && <span>{name}</span>}
          <div className='bullet-description'>
            <RichText render={description} />
          </div>
        </div>
      </div>
      <style jsx>{`
        div.container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-bottom: 5rem;
          width: 22.813rem;
          width: 100%;
        }

        div.container span {
          font-size: 1.5rem;
          margin-bottom: 1rem;
        }

        div.container .logos {
          display: flex;
        }

        div.container img {
          height: 8.75rem;
          max-height: 8.75rem;
          max-width: 8.75rem;
          object-fit: cover;
          object-position: 0% 10%;
          margin: 0 auto 1rem;
          display: block;
        }

        div.container .bullet-description {
          padding-top: 1rem;
        }

        @media only screen and (min-width: ${theme.breakpoints.desktopMin}) {
          div.container {
            max-width: 22.813rem;
          }
        }
      `}</style>
      <style global jsx>{`
        div.container .bullet-description p {
          margin-block-start: 0;
          margin-block-end: 0;
          line-height: 1.7rem;
          text-align: center;
        }

        div.container .bullet-description p a {
          color: ${theme.fontColors.primary};
        }

        @media only screen and (min-width: ${theme.breakpoints.desktopMin}) {
          div.container .bullet-description p {
            width: 100%;
          }
        }
      `}</style>
    </>
  );
};

export default Bullet;
