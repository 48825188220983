import { nanoid } from 'nanoid';

// Components
import Carousel from '@/components/global-components/carousel/Carousel';
import TopBanner from '@/components/global-components/top-banner/TopBanner';
import { carousel } from '@/scripts/constants';

export const MainSlide = ({
  content: {
    speed,
    slidesGroup,
  },
  isDesktop,
}) => (
  <>
    <Carousel
      showPaging={true}
      transitionMode={carousel.FADE}
      autoplay={true}
      autoplayInterval={speed * 1000}
      wrapAround={true}
    >
      {slidesGroup.map(
        ({
          slide: {
            backgroundImage,
            title,
            subTitle,
            callToActions,
          },
        }) => (
          <TopBanner
            key={nanoid()}
            backgroundImage={backgroundImage}
            title={title}
            subTitle={subTitle}
            callToActions={callToActions}
            target='_blank'
            subTitleStyle={{
              padding: isDesktop ?? '0 15rem', 
            }}
          />
        )
      )}
    </Carousel>
  </>
);

export default MainSlide;
