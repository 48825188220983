// Node Modules
import axios from 'axios';

export async function fetchPosts (first, last, searchText, beforeCursor, afterCursor) {
  return await axios.get('/api/posts', {
    params: {
      first,
      last,
      searchText,
      before: beforeCursor,
      after: afterCursor,
    },
  })
    .catch(function (error) {
      console.log('ERROR: ', error);
    })
}